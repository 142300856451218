
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
  .about {
    padding-top: 60px;
  }

  .about .content h3 {
    font-weight: 700;
    font-size: 26px;
    color: #728394;
    margin-bottom: 60px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }

  .about .content ul a {
    color: black;
  }
  
  .about .content ul li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .about .content ul strong {
    margin-right: 10px;
  }
  
  .about .content ul i {
    font-size: 16px;
    margin-right: 5px;
    color: #0563bb;
    line-height: 0;
  }
  
  .about .content p:last-child {
    margin-bottom: 0;
  }
  