#hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: url("../img/d0c6e88e-4f27-4e9d-b8bb-46afe7503d7b.JPG") top right no-repeat;
    /* background: url("../img/Tristan_Chandos_Lake.JPEG") top right no-repeat; */
    /* background: url("../img/Tristan_Photo.png") top right no-repeat; */
    background-size: cover;
    position: relative;
  }
  
  @media (min-width: 992px) {
    #hero {
      padding-left: 160px;
    }
  }
  
  #hero:before {
    content: "";
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  
  #hero h1 {
    margin: 0;
    font-size: 64px;
    font-weight: 700;
    line-height: 56px;
    color: #45505b;
  }
  
  #hero p {
    color: #45505b;
    margin: 15px 0 0 0;
    font-size: 26px;
    font-family: "Poppins", sans-serif;
  }
  
  #hero p span {
    color: #0563bb;
    letter-spacing: 1px;
  }
  
  #hero .social-links {
    margin-top: 30px;
  }
  
  #hero .social-links a {
    font-size: 24px;
    display: inline-block;
    color: #45505b;
    line-height: 1;
    margin-right: 20px;
    transition: 0.3s;
  }
  
  #hero .social-links a:hover {
    color: #0563bb;
  }
  
  @media (max-width: 992px) {
    #hero {
      text-align: center;
    }
  
    #hero h1 {
      font-size: 32px;
      line-height: 36px;
    }
  
    #hero p {
      margin-top: 10px;
      font-size: 20px;
      line-height: 24px;
    }
  }

.typingAnimation {
    margin-left: 7px;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
