/*--------------------------------------------------------------
# projects
--------------------------------------------------------------*/
.projects-logo {
  font-family: boxicons!important;
  font-weight: 400;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  display: inline-block;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.projects .icon-box {
    text-align: center;
    padding: 70px 20px 80px 20px;
    transition: all ease-in-out 0.3s;
    background: #fff;
    box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.05);
  }
  
  .projects .icon-box .icon {
    margin: 0 auto;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in-out 0.3s;
    position: relative;
  }

  .projects .icon-box .icon i {
    font-size: 36px;
    transition: 0.5s;
    position: relative;
  }
  
  .projects .icon-box .icon .projects-logo{
    font-size: 36px;
    transition: 0.5s;
    position: relative;
  }
  
  .projects .icon-box .icon svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .projects .icon-box .icon svg path {
    transition: 0.5s;
    fill: #f5f5f5;
  }
  
  .projects .icon-box h4 {
    font-weight: 600;
    margin: 10px 0 15px 0;
    font-size: 22px;
  }
  
  .projects .icon-box h4 a {
    color: #45505b;
    transition: ease-in-out 0.3s;
  }
  
  .projects .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  
  .projects .icon-box:hover {
    border-color: #fff;
    box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
  }

  .projects .iconbox-blue i {
    color: #47aeff;
  }
  
  .projects .iconbox-blue:hover .icon i {
    color: #fff;
  }
  
  
  .projects .iconbox-blue #projects-logo {
    color: #47aeff;
  }
  
  .projects .iconbox-blue:hover .icon #projects-logo {
    color: #fff;
  }
  
  .projects .iconbox-blue:hover .icon path {
    fill: #47aeff;
  }

  /* .projects .iconbox-orange i {
    color: #ffa76e;
  }

  .projects .iconbox-orange:hover .icon i {
    color: #fff;
  }
  
  .projects .iconbox-orange .projects-logo{
    color: #ffa76e;
  }
  
  .projects .iconbox-orange:hover .icon .projects-logo{
    color: #fff;
  }
  
  .projects .iconbox-orange:hover .icon path {
    fill: #ffa76e;
  } */

  .projects .iconbox-pink i {
    color: #e80368;
  }
  
  .projects .iconbox-pink:hover .icon i {
    color: #fff;
  }
  
  .projects .iconbox-pink .projects-logo{
    color: #e80368;
  }
  
  .projects .iconbox-pink:hover .icon .projects-logo{
    color: #fff;
  }
  
  .projects .iconbox-pink:hover .icon path {
    fill: #e80368;
  }
  
  .projects .iconbox-yellow i {
    color: #ffbb2c;
  }
  
  .projects .iconbox-yellow:hover .icon i {
    color: #fff;
  }

  .projects .iconbox-yellow .projects-logo{
    color: #ffbb2c;
  }
  
  .projects .iconbox-yellow:hover .icon .projects-logo{
    color: #fff;
  }
  
  .projects .iconbox-yellow:hover .icon path {
    fill: #ffbb2c;
  }

  .projects .iconbox-red i {
    color: #ff5828;
  }
  
  .projects .iconbox-red:hover .icon i {
    color: #fff;
  }
  
  .projects .iconbox-red .projects-logo{
    color: #ff5828;
  }
  
  .projects .iconbox-red:hover .icon .projects-logo{
    color: #fff;
  }
  
  .projects .iconbox-red:hover .icon path {
    fill: #ff5828;
  }

  .projects .iconbox-teal i {
    color: #11dbcf;
  }
  
  .projects .iconbox-teal:hover .icon i {
    color: #fff;
  }
  
  .projects .iconbox-teal .projects-logo{
    color: #11dbcf;
  }
  
  .projects .iconbox-teal:hover .icon .projects-logo{
    color: #fff;
  }
  
  .projects .iconbox-teal:hover .icon path {
    fill: #11dbcf;
  }

  /* Fooling around with one colour */

  .projects .iconbox-orange .orange_icon {
    color: #ffa76e;
  }
  
  .projects .iconbox-orange:hover .icon .orange_icon {
    color: #fff;
  }

  .projects .iconbox-orange:hover .icon .orange_blob {
    fill: #ffa76e;
  }