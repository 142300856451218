/*--------------------------------------------------------------
# personal-projects Details
--------------------------------------------------------------*/
.personal-projects-details {
    padding-top: 40px;
  }
  
  .personal-projects-details .personal-projects-details-slider img {
    width: 100%;
  }
  
  .personal-projects-details .personal-projects-details-slider .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .personal-projects-details .personal-projects-details-slider .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #0563bb;
  }
  
  .personal-projects-details .personal-projects-details-slider .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #0563bb;
  }
  
  .personal-projects-details .personal-projects-info {
    padding: 30px;
    box-shadow: 0px 0 30px rgba(69, 80, 91, 0.08);
  }
  
  .personal-projects-details .personal-projects-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }
  
  .personal-projects-details .personal-projects-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .personal-projects-details .personal-projects-info ul li+li {
    margin-top: 10px;
  }
  
  .personal-projects-details .personal-projects-description {
    padding-top: 30px;
  }
  
  .personal-projects-details .personal-projects-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .personal-projects-details .personal-projects-description p {
    padding: 0;
  }