.game {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.game input, .game button {
    margin: 10px;
}

.game .word-chain {
    margin-top: 20px;
    font-size: 1.2rem;
}

.error {
    color: red;
}
